<template>
  <v-container fluid>

    <v-row>
      <!--menu-->
      <v-col cols="12" sm="12" md="3">
        <b-card :class="[this.$vuetify.theme.dark? 'dark-bg' : '', 'mb-1']" no-body>
          <b-card-header class="p-0" header-tag="header" role="tab">
            <v-list-item @click="opened =! opened" class="ma-0"
                         v-b-toggle.global-settings>
              <v-list-item-title :class="this.opened? 'primary--text text-wrap' : 'text-wrap'">
                {{ this.$t('settings.lang_settings') }}
              </v-list-item-title>
              <v-list-item-action>
                <v-icon v-if="!this.opened">keyboard_arrow_down</v-icon>
                <v-icon v-if="this.opened">keyboard_arrow_up</v-icon>
              </v-list-item-action>
            </v-list-item>
          </b-card-header>
          <b-collapse :visible="this.opened" accordion="global-settings" id="global-settings"
                      role="tabpanel">
            <b-card-body class="pa-0">
              <b-list-group flush>
                <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'service_status'? 'primary--text' : '']"
                                   @click="handleClick('service_status')"
                                   class="items">
                  {{ this.$t('tablebee.lang_service_status') }}
                </b-list-group-item>

                <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'service_time'? 'primary--text' : '']"
                                   @click="handleClick('service_time')"
                                   class="items">
                  {{ this.$t('tablebee.lang_service_times') }}
                </b-list-group-item>

                <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'payment'? 'primary--text' : '']"
                                   @click="handleClick('payment')"
                                   class="items">
                  {{ this.$t('generic.lang_zahlungsoptionen') }}
                </b-list-group-item>

                <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'settings'? 'primary--text' : '']"
                                   @click="handleClick('settings')"
                                   class="items">
                  {{ this.$t('settings.lang_settings') }}
                </b-list-group-item>
              </b-list-group>
            </b-card-body>
          </b-collapse>
        </b-card>

      </v-col>


      <!--content-->
      <v-col class="py-0" cols="12" sm="12" md="9">
        <v-card>
          <v-card-text class="ma-0 pa-0">
            <v-container class="ma-0 pa-0">
              <v-row no-gutters>
                <v-col>
                  <template>
                    <div role="tablist">

                      <kiosk-status-component v-if="currentTab === 'service_status'"/>

                      <kiosk-service-times-component v-if="currentTab === 'service_time'"/>

                      <kiosk-payment-option-component v-if="currentTab === 'payment'"/>

                      <kiosk-other-settings-component v-if="currentTab === 'settings'"/>
                    </div>

                  </template>
                </v-col>

              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>

  </v-container>


</template>

<script>
import KioskServiceTimesComponent from "@/components/kiosk/settings/KioskServiceTimesComponent";
import KioskPaymentOptionComponent from "@/components/kiosk/settings/KioskPaymentOptionComponent";
import KioskStatusComponent from "@/components/kiosk/settings/KioskStatusComponent";
import KioskOtherSettingsComponent from "./KioskOtherSettingsComponent";
// import KioskOtherSettingsComponent from "./KioskOtherSettingsComponent";

export default {
  name: "KioskSettingComponent",
  components: {
    KioskOtherSettingsComponent,
    // KioskOtherSettingsComponent,
    KioskStatusComponent, KioskPaymentOptionComponent, KioskServiceTimesComponent
  },
  data() {
    return {
      opened: true,
      currentTab: "service_status"
    }
  },
  methods: {
    handleClick(newTab) {
      if (newTab && newTab.length > 0) {
        this.currentTab = newTab;
        this.$router.push({query: {tab: newTab}})
      }
    },
  },
  mounted() {
    this.handleClick(this.$route.query.tab)
  }
}
</script>

<style scoped>
.items {
  cursor: pointer;
  user-select: none;
}
</style>
