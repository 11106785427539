<template>
  <div class="main-card mb-3 card pa-0 ma-0 transparent">
    <div class="card-body pa-0">
      <div
        :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
        class="card-title card-header-tab card-header"
      >
        <div
          class="card-header-title font-size-lg text-capitalize font-weight-normal"
        >
          {{ this.$t("generic.lang_zahlungsoptionen") }}
        </div>
        <div class="btn-actions-pane-right actions-icon-btn">
          <div class="btn-actions-pane-right actions-icon-btn">
            <v-btn
              :disabled="loading"
              depressed
              :loading="loading"
              @click="update"
              class="elevation-0"
              color="success"
            >
              {{ this.$t("generic.lang_save") }}
            </v-btn>
          </div>
        </div>
      </div>

      <v-row class="py-0">
        <v-col class="pb-0" cols="12">
          <v-card class="pa-0">
            <v-data-table
              :headers="dataTableHeader"
              :items="payments"
              :loading="loading"
              :items-per-page="-1"
            >
              <template v-slot:item.active="{ item }">
                <v-checkbox v-model="item.active"> </v-checkbox>
              </template>

              <template v-slot:item.kiosk_usePayCashBeforeOrder="{ item }">
                <v-checkbox
                  v-if="
                    item && item.hasOwnProperty('kiosk_usePayCashBeforeOrder')
                  "
                  v-model="item.kiosk_usePayCashBeforeOrder"
                  :label="$t('kiosk.lang_usePayCashBeforeOrder')"
                ></v-checkbox>
              </template>
              
              <template v-slot:item.crudEdit="{ item }">
                <v-btn
                  elevation="0"
                  color="primary"
                  icon
                  @click="editEntry(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
    
    <v-dialog v-model="dialogEdit" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">{{
              this.$t("tablebee.lang_edit_payment_method_translation")
            }}</span>
          </v-card-title>

          <v-card-text class="mt-3">
            <v-form
              v-model="valid"
              ref="payementType"
              lazy-validation
              style="width: 100%"
            >
              <b-tabs>
                <b-tab active>
                  <template #title>
                    <v-btn text class="ma-0">
                      <country-flag country="DE" />
                    </v-btn>
                  </template>

                  <v-row no-gutters>
                    <v-col class="" cols="12">
                      <v-subheader>
                        <strong>
                          {{
                            this.$t("accounting.lang_payment_method_") +
                            `: ${this.slectedPayement.paymentName}`
                          }}
                        </strong>
                      </v-subheader>
                      <v-text-field
                        hide-details
                        outlined
                        dense
                        flat
                        v-model="paymentType_translations.de"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </b-tab>

                <b-tab>
                  <template #title>
                    <v-btn text class="ma-0">
                      <country-flag country="GB" />
                    </v-btn>
                  </template>

                  <v-row no-gutters>
                    <v-col class="" cols="12">
                      <v-subheader>
                        <strong>
                          {{
                            this.$t("accounting.lang_payment_method_") +
                            `: ${this.slectedPayement.paymentName}`
                          }}
                        </strong>
                      </v-subheader>
                      <v-text-field
                        hide-details
                        outlined
                        dense
                        flat
                        v-model="paymentType_translations.en"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </b-tab>

                <b-tab>
                  <template #title>
                    <v-btn text class="ma-0">
                      <country-flag country="FR" />
                    </v-btn>
                  </template>

                  <v-row no-gutters>
                    <v-col class="" cols="12">
                      <v-subheader>
                        <strong>
                          {{
                            this.$t("accounting.lang_payment_method_") +
                            `: ${this.slectedPayement.paymentName}`
                          }}
                        </strong>
                      </v-subheader>
                      <v-text-field
                        hide-details
                        outlined
                        dense
                        flat
                        v-model="paymentType_translations.fr"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </b-tab>

                <b-tab>
                  <template #title>
                    <v-btn text class="ma-0">
                      <country-flag country="SA" />
                    </v-btn>
                  </template>

                  <v-row no-gutters>
                    <v-col class="" cols="12">
                      <v-subheader>
                        <strong>
                          {{
                            this.$t("accounting.lang_payment_method_") +
                            `: ${this.slectedPayement.paymentName}`
                          }}
                        </strong>
                      </v-subheader>
                      <v-text-field
                        hide-details
                        outlined
                        dense
                        flat
                        v-model="paymentType_translations.ar"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </b-tab>
              </b-tabs>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cancelEditDialog()">
              {{ $t("generic.lang_cancel") }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              :disabled="!valid"
              text
              @click="editPayementType()"
            >
              {{ $t("generic.lang_edit") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import CountryFlag from "vue-country-flag";

export default {
  name: "KioskPaymentOptionComponent",
  components: {
    CountryFlag,
  },
  data() {
    return {
      loading: false,
      payments: [],
      dialogEdit:false,
      valid:false,
      slectedPayement: {},
      paymentType_translations: { de: "", en: "", fr: "", ar: "" },
    };
  },
  computed: {
    dataTableHeader(){
      return [
        {
          text: this.$t("accounting.lang_payment_id"),
          hide: true,
          value: "id",
          width: 100,
        },
        {
          text: this.$t("accounting.lang_payment_method_"),
          value: "paymentName",
        },
        {
          text: this.$t("settings.lang_settings_activ"),
          value: "active",
        },
        {
          text: "",
          value: "kiosk_usePayCashBeforeOrder",
        },
        {
          text: "",
          value: "crudEdit",
          align: "center",
          width: 100,
        },
      ];
    }
  },
  methods: {
    update() {
      let data = [];
      if (Array.isArray(this.payments))
        data = this.payments.map(({ id, active, kiosk_usePayCashBeforeOrder, translations }) => ({
          id,
          is_active: active === true,
          kiosk_usePayCashBeforeOrder,
          translations
        }));

      this.loading = true;

      this.axios
        .post(ENDPOINTS.KIOSK.SETTINGS.PAYMENTS.BULKACTIVE, {
          payments: data,
        })
        .then((res) => {
          if (res.data.status === "success") {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_dataSaved'),
              color: "success",
            });
          } else {
            Events.$emit("showSnackbar", {
              message: res.data.msg || res.data.status,
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getData() {
      this.loading = true;
      this.axios
        .post(ENDPOINTS.KIOSK.SETTINGS.PAYMENTS.GET)
        .then((res) => {
          this.payments = res.data.formfillData.textFields;
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editEntry(entry) {
      this.dialogEdit = true;
      this.slectedPayement = entry;
      if(entry.translations){
        Object.assign(this.paymentType_translations, entry.translations);
      }
    },
    cancelEditDialog() {
      this.dialogEdit = false;
      this.$refs.payementType.reset();
    },
    editPayementType() {
      this.slectedPayement.translations = Object.assign({} ,this.paymentType_translations);
      this.update();
      this.cancelEditDialog();
    }
  },
  mounted() {
    this.getData();
  },
};
</script>
